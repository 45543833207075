<template>
  <v-btn color="success" depressed size="small" @click="generatePDF">
    <v-icon left>mdi-printer</v-icon> Rel. Produtividade Resumo
  </v-btn>
</template>

    
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsPDF from '../../service/utilsPDF';
import UtilsFunc from "../../service/utilsFunc";
import logo from "../../assets/logo.png";
import gptw from "../../assets/GPTW_2023-2024.png"
import ona from "../../assets/ONA_Nivel_2.png"

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Produtividade",
  data: () => withCRUDUtils({
    loading: false,
  }),
  props: {
    dados: { type: Array, default: () => [] },
    data_inicio: String,
    data_fim: String,
  },
  methods: {
    drawHeader(doc) {
      // console.log(this.dados)
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF: (L-37):", error);
      }
      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Relatório de Produção`, 105, 23, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      doc.text("Data e Hora da Impressão:", 200, 32, { align: "right" });
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, 200, 36, {
        align: "right",
      });

      doc.setFontSize(11);
      doc.text(`Período de: ${UtilsPDF.formatDate(this.data_inicio)} até: ${UtilsPDF.formatDate(this.data_fim)}`, 71, 35);
    },
    async generatePDF() {
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );

      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      const lineHeight = 4;
      let yPosition = 35;

      doc.setFont("helvetica", "normal");
      yPosition += lineHeight * 2;

      // Encontrar o array 'resumo' dentro dos dados recebidos
      const resumoData = this.dados.find(item => item.resumo)?.resumo;
      if (!resumoData) {
        alert('Dados de resumo não encontrados.');
        return;
      }
      // const name = resumoData[0].colaborador_nome
      const aggregatedData = resumoData.reduce((acc, item) => {
        if (!acc[item.colaborador_id]) {
          acc[item.colaborador_id] = { ...item, qtd_ate: 0, qtd_assinadas: 0, valor_a_pagar: 0 };
        }
        acc[item.colaborador_id].qtd_ate += item.qtd_ate;
        acc[item.colaborador_id].qtd_assinadas += item.qtd_assinadas;
        acc[item.colaborador_id].valor_a_pagar += parseFloat(item.valor_a_pagar);
        return acc;
      }, {});

      const aggregatedArray = Object.values(aggregatedData).sort((a, b) => a.colaborador.localeCompare(b.colaborador));
      const blueColor = [57, 106, 159];
      const valorTotal = resumoData.reduce((total, item) => total + parseFloat(item.valor_a_pagar), 0);
      let counter = 1; 

      doc.autoTable({
        startY: yPosition,
        theme: 'striped',
        headStyles: {
          fillColor: blueColor,
          halign: 'center',
        },
        styles: {
          lineColor: blueColor,
          lineWidth: 0.1,
        },
        head: [['','Id Prof.', 'Profissional', 'Especialidade', 'Qtd Evolução', 'Qtd Evol. Validadas', 'Valor da Produção']],
        body: aggregatedArray.map(item => [
          counter++,
          item.colaborador_id,
          item.colaborador_nome,
          item.especialidade_nome,
          item.qtd_ate,
          item.qtd_assinadas,
          UtilsPDF.formatToBRL(item.valor_a_pagar)
        ]),
        columnStyles: {
          2: { cellWidth: 70 }, // Define a largura da primeira coluna (Profissional)
        },
      });

      const tableHeight = doc.autoTable.previous.finalY; // Obtém a altura da tabela
      yPosition = tableHeight + lineHeight + 2;

      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text(`Valor Total da Produção: ${UtilsPDF.formatToBRL(valorTotal)}`, 195, yPosition, { align: "right" });

      UtilsPDF.addPageNumbers(doc);

      const filename = `Relatório Produtividade - Período ${UtilsPDF.formatDate(this.data_inicio)} - ${UtilsPDF.formatDate(this.data_fim)}.pdf`;
      // UtilsPDF.savePDF(doc, filename, this.$toast);
      doc.save(filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },


  },
};
</script>